import { loginByCorp } from '../../apis';
import { fetchHandler, setAuthorization } from '../../utils';
export default {
    name: 'EngineSelectMobile',
    props: {
        engines: {
            type: Array
        },
    },
    setup(props, { emit }) {
        const selectEngine = async (engine) => {
            const enginecode = engine.Key;
            const ret = await loginByCorp({ enginecode });
            fetchHandler(ret, () => {
                // 重新存储cookie
                const { token, shardKey, dingtalkcorpid } = ret.result;
                setAuthorization(token || '', shardKey || '');
                emit('loginSuccess', dingtalkcorpid);
            });
        };
        return {
            selectEngine
        };
    }
};
