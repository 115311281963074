import { defineComponent } from 'vue';
import { scanLoginByAssOrg, verifyAssOrg, makeAssociated, EventRecordLog } from '@/apis/index';
import { isDingtalk, setAuthorization } from '../../utils';
import { ddAlert } from '../../lib/dingtalk';
import EngineSelectMobile from './engine-select-mobile.vue';
export default defineComponent({
    name: 'AssociatedOrganization',
    components: {
        EngineSelectMobile,
    },
    beforeRouteLeave(to, from, next) {
        this.isShowEngineSelect = false;
        next();
    },
    data() {
        return {
            state: '',
            companyname: '',
            errmsg: '',
            enginecode: '',
            guid: '',
            corpId: '',
            appid: '',
            code: '',
            engines: [],
            isShowEngineSelect: true,
        };
    },
    async created() {
        // 信任通道埋点 受邀访问
        EventRecordLog('', '', 'AssociatedOrganization.Visit', '', true);
        if (this.$route.query) {
            this.enginecode = this.$route.query.enginecode || '';
            this.guid = this.$route.query.guid || '';
            this.appid = this.$route.query.appid || '';
            this.code = this.$route.query.code || '';
            const res = await verifyAssOrg({
                EngineCode: this.enginecode,
                Code: this.guid
            });
            this.companyname = (res.ReturnData && res.ReturnData.Data && res.ReturnData.Data.companyname) || '';
            this.errmsg = res.errmsg || res.ErrorMessage || (res.ReturnData && res.ReturnData.Data && res.ReturnData.Data.errormsg);
            if (res.errcode !== 0) {
                switch (res.errcode) {
                    case 205003: // 已关闭
                        this.state = 'isClose';
                        break;
                    case 205004: // 非付费
                        this.state = 'isNonPaid';
                        break;
                    default: // 其他
                        this.state = 'isOthers';
                        break;
                }
            }
            else {
                const ret = await scanLoginByAssOrg({ code: this.code, suitekey: this.appid });
                if (!ret.errcode) {
                    const { token, shardKey, havecorps, corps, dingtalkcorpid } = ret.result;
                    setAuthorization(token || '', shardKey || '');
                    if (!havecorps) {
                        this.corpId = dingtalkcorpid || '';
                        this.state = 'isLogined';
                    }
                    else {
                        this.engines = corps;
                        this.isShowEngineSelect = true;
                    }
                }
                else {
                    if (isDingtalk) {
                        ddAlert('提示', ret.errmsg);
                    }
                    else {
                        alert('请用钉钉扫码');
                    }
                }
            }
        }
    },
    methods: {
        async onSureAssociated() {
            const ret = await makeAssociated({
                enginecode: this.enginecode,
                guid: this.guid
            });
            if (ret.ReturnData && ret.ReturnData.Data && ret.ReturnData.Data.errorcode === 0) {
                this.state = 'isSuccessAssociated';
                // 信任通道埋点 互通成功
                EventRecordLog('', '', 'AssociatedOrganization.Associate.Success', '', true);
            }
            else {
                const errcode = ret.ReturnData && ret.ReturnData.Data && ret.ReturnData.Data.errorcode;
                this.companyname = (ret.ReturnData && ret.ReturnData.Data && ret.ReturnData.Data.companyname) || '';
                this.errmsg = ret.ErrorMessage || (ret.ReturnData && ret.ReturnData.Data && ret.ReturnData.Data.errormsg);
                // 信任通道埋点 互通失败
                EventRecordLog('', '', 'AssociatedOrganization.Associate.Fail', '', true);
                switch (errcode) {
                    case 110003: // 非管理员
                        this.state = 'ownIsNonAdminister';
                        break;
                    case 110004: // 已连接
                        this.state = 'ownIsAssociated';
                        break;
                    case 110005: // 非付费
                        this.state = 'ownIsNonPaid';
                        break;
                    default: // 其他
                        this.state = 'ownIsOthers';
                        break;
                }
            }
        },
        loginSuccess(corpid) {
            this.state = 'isLogined';
            this.corpId = corpid;
            this.isShowEngineSelect = false;
        },
        goToH3yun() {
            window.location.replace(`${window.location.origin}/index.html?corpId=${this.corpId}&t=${new Date().getTime()}`);
        }
    }
});
